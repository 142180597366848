@use '@angular/material' as mat;
@include mat.core();

@import './theme/theme.scss';

html,
body {
  height: 100%;
}

body {
  margin: 0;
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
