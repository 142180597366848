@import './colors.scss';
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

$app-font-family: 'Poppins', sans-serif;

$app-primary: mat.define-palette($md-primary, 500);
$app-accent: mat.define-palette($md-secondary, 500);
$app-warn: mat.define-palette(mat.$red-palette);
$app-typography: mat.define-typography-config(
	$font-family: $app-font-family,
);

$app-theme: mat.define-light-theme(
  (
    color: (
      primary: $app-primary,
      accent: $app-accent,
      warn: $app-warn,
    ),
    typography: $app-typography,
  )
);

@include mat.all-component-themes($app-theme);
